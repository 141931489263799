import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { bool, node, string } from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { CustomExtendedDataField, FieldPhoneNumberInput, FieldTextInput, Form, PrimaryButton } from '../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';
import * as validators from '../../../util/validators';
import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';
import css from './SignupForm.module.css';
import { ConfigFieldInputKey } from '../../../config/configListing';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import '../../../styles/react-phone-input-2.css'

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => {
  if (typeof window === 'undefined') return null;
  require('../../../styles/react-phone-input-2.css');


  const { isMerchant } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevState => !prevState);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={{
        userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          form,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          preselectedUserType,
          userTypes,
          userFields,
          values,
        } = formRenderProps;

        const { userType, password, isPasswordMismatch, mobile_phone_number } = values || {};

        // Email field validation
        const emailRequired = validators.required(
          intl.formatMessage({ id: 'SignupForm.emailRequired' })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({ id: 'SignupForm.emailInvalid' })
        );

        const passwordValid = validators.passwordFormatValid(
          intl.formatMessage({ id: 'SignupForm.passwordInvalid' })
        );

        // Password field validation
        const passwordRequiredMessage = intl.formatMessage({ id: 'SignupForm.passwordRequired' });

        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(passwordRequired, passwordValid);

        // Confirm Password validation
        const confirmPasswordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.confirmPasswordRequired',
        });

        const confirmPasswordRequired = validators.requiredStringNoTrim(
          confirmPasswordRequiredMessage
        );

        const confirmPasswordMatch = value =>
          value === password
            ? undefined
            : intl.formatMessage({ id: 'SignupForm.confirmPasswordMismatch' });

        const confirmPasswordValidators = validators.composeValidators(
          confirmPasswordRequired
        );

        // Date of Birth validation
        const dateOfBirthRequired = validators.required(
          intl.formatMessage({ id: 'SignupForm.dateOfBirthRequired' })
        );

        // Custom user fields
        const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

        // Determine if user type and custom fields should be shown
        const noUserTypes = !userType && !(userTypes?.length > 0);
        const userTypeConfig = userTypes.find(config => config.userType === userType);
        const showDefaultUserFields = userType || noUserTypes;
        const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

        const classes = classNames(rootClassName || css.root, className, isMerchant && css.merchantForm);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || isPasswordMismatch;

        const handleChangePhoneInput = (value, country) => {
          const { name = '', dialCode = '', countryCode = '', format } = country;
          form.change('phoneNumber', '+' + value);
          const newState = {
            countryCode: countryCode?.toUpperCase(),
            callingCode: dialCode,
            phoneNumber: value?.replace(dialCode, ""),
            value: value
          }
          form.change('mobile_phone_number', newState);
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldSelectUserType
              name="userType"
              userTypes={userTypes}
              hasExistingUserType={!!preselectedUserType}
              intl={intl}
              className={css.inputBox}
            />

            {showDefaultUserFields ? (
              <div className={css.defaultUserFields}>
                {showDefaultUserFields !== 'provider' ? (
                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    className={css.inputBox}
                    autoComplete="off"
                    label={intl.formatMessage({ id: 'SignupForm.emailLabel' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.emailPlaceholder1' })}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                ) : null}

                {showDefaultUserFields === "provider" ?
                  <FieldTextInput
                    id={`${formId}business_name`}
                    name="business_name"
                    className={css.inputBox}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'EditListingBusinessForm.businessNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'EditListingBusinessForm.businessNameRequired' })
                    )}
                  />
                  : null}

                <PhoneInput
                  className={css.phoneInput}
                  name="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  country={'sa'}
                  value={mobile_phone_number?.value}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                  })}
                  onChange={handleChangePhoneInput}
                />

                {/* <FieldPhoneNumberInput
                  id={`${formId}.businessPhoneNumber`}
                  name={ConfigFieldInputKey?.BUSINESS_PHONE_NUMBER}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                  })}
                  className={css.inputBox}
                  validate={validators.phoneNumberValid(
                    intl.formatMessage({ id: 'EditListingDetailsForm.phoneNumberInvalid' })
                  )}
                /> */}

                <div className={classNames(css.name)}>
                  <FieldTextInput
                    className={css.inputBox}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="off"
                    label={intl.formatMessage({ id: 'SignupForm.firstNameLabel' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder1' })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'SignupForm.firstNameRequired' })
                    )}
                  />
                  <FieldTextInput
                    className={css.inputBox}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="off"
                    label={intl.formatMessage({ id: 'SignupForm.lastNameLabel' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.lastNamePlaceholder1' })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'SignupForm.lastNameRequired' })
                    )}
                  />
                </div>

                {showDefaultUserFields === "provider" ?
                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    className={css.inputBox}
                    autoComplete="off"
                    label={intl.formatMessage({ id: 'SignupForm.emailLabel' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.emailPlaceholder1' })}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                  : null}

                {/* Date of Birth Field */}
                {showDefaultUserFields !== 'provider' ? (
                  <>
                    {showCustomUserFields ? (
                      <div className={classNames(css.inputBox, css.calenderInput)}>
                        {userFieldProps.map(fieldProps => (
                          <CustomExtendedDataField {...fieldProps} formId={formId} />
                        ))}
                      </div>
                    ) : null}
                  </>
                ) : null}

                {/* Password Field */}
                <div className={css.passwordContainer}>
                  <FieldTextInput
                    showPassword={showPassword}
                    handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                    showPass={true}
                    className={css.inputBox}
                    type={showPassword ? 'text' : 'password'}
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    autoComplete="new-password"
                    label={intl.formatMessage({ id: 'SignupForm.passwordLabel' })}
                    placeholder={intl.formatMessage({ id: 'SignupForm.passwordPlaceholder' })}
                    validate={passwordValidators}
                    onChange={e => {
                      const { value } = e.target;
                      form.change('password', value);

                      value !== values?.confirmPassword ?
                        form.change('isPasswordMismatch', true)
                        : form.change('isPasswordMismatch', false);
                    }}
                  />
                </div>

                {/* Confirm Password Field */}
                <div className={css.passwordContainer}>
                  <FieldTextInput
                    showPassword={showConfirmPassword}
                    handleTogglePasswordVisibility={handleToggleConfirmPasswordVisibility}
                    showPass={true}
                    className={css.inputBox}
                    type={showConfirmPassword ? 'text' : 'password'}
                    id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                    name="confirmPassword"
                    autoComplete="new-password"
                    label={intl.formatMessage({ id: 'SignupForm.confirmPasswordLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.confirmPasswordPlaceholder',
                    })}
                    validate={confirmPasswordValidators}
                    onChange={e => {
                      const { value } = e.target;
                      form.change('confirmPassword', value);

                      value !== values?.password ?
                        form.change('isPasswordMismatch', true)
                        : form.change('isPasswordMismatch', false);
                    }}
                  />
                </div>

                {values?.confirmPassword && isPasswordMismatch ?
                  <div className={css.errorText}><FormattedMessage id='SignupForm.confirmPasswordMismatch' /></div>
                  : null}

                {/* <UserFieldPhoneNumber
                  formName="SignupForm"
                  className={css.row}
                  userTypeConfig={userTypeConfig}
                  intl={intl}
                /> */}
              </div>
            ) : null}

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                {isMerchant ? <FormattedMessage id="SignupForm.signUpMerchant" /> : <FormattedMessage id="SignupForm.signUp" />}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
